// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-flow-js": () => import("./../../../src/templates/flow.js" /* webpackChunkName: "component---src-templates-flow-js" */),
  "component---src-templates-hardware-js": () => import("./../../../src/templates/hardware.js" /* webpackChunkName: "component---src-templates-hardware-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-industries-js": () => import("./../../../src/templates/industries.js" /* webpackChunkName: "component---src-templates-industries-js" */),
  "component---src-templates-platform-js": () => import("./../../../src/templates/platform.js" /* webpackChunkName: "component---src-templates-platform-js" */),
  "component---src-templates-remote-expert-js": () => import("./../../../src/templates/remoteExpert.js" /* webpackChunkName: "component---src-templates-remote-expert-js" */)
}

